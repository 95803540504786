<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="mb-8 mt-4 d-flex align-center justify-space-between">
          <div class="text-h5 font-weight-medium">
            Техкарты
          </div>
          <router-link tag="a" to="/new-map">
            <v-btn dark color="primary" class="ml-4" rounded>
              Добавить
            </v-btn>
          </router-link>
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-select class="mr-4" :items="items" label="Фильтры" solo hide-details />
          <v-text-field placeholder="Поиск" solo append-icon="mdi-magnify" hide-details />
        </div>

        <v-data-table :headers="headers" :items="GET_TECH_MAPS" :loading="GET_LOADING" :items-per-page="5" class="elevation-1"
          @dblclick:row="openTechCard">
          <template v-slot:item.id="{ item }">
            <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>{{item.id}}</v-chip>
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{formatDate(item.date)}}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn fab color="grey darken-3" x-small text>
              <v-icon dark>
                mdi-magnify
              </v-icon>
            </v-btn>
            <v-btn @click="deleteMap(item.id)" fab dark color="red" x-small text>
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formatDate from '@/mixins/formatDate'
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from "vuex";

  export default {
    name: "TechMaps",
    mixins: [formatDate],
    props: {
      id: {
        type: Number,
        default: null,
      },
    },

    data() {
      return {
        headers: [{
            text: "№",
            value: "id"
          },
          {
            text: "Техкарта",
            value: "title"
          },
          {
            text: "Дата",
            value: "date"
          },
          {
            text: "Комментарий",
            value: "description"
          },
          {
            text: "Действия",
            value: "actions"
          },
        ],
        items: [],
      };
    },

    computed: {
      ...mapGetters({
        GET_LOADING: "techMaps/GET_LOADING",
        GET_TECH_MAPS: "techMaps/GET_TECH_MAPS"
      }),
    },

    async created() {
      await this.UPDATE_TECH_MAPS();
    },

    methods: {
      ...mapActions({
        UPDATE_TECH_MAPS: "techMaps/UPDATE_TECH_MAPS",
        DELETE_TECH_MAPS: "techMaps/DELETE_TECH_MAPS"
      }),
      async deleteMap(id){
          await this.DELETE_TECH_MAPS(id)
      },
      openTechCard(event, {item}) {
        this.$router.push(`/tech-maps/${item.id}`);
      }
    },
  };
</script>