import axios from 'axios'
import Vue from "vue";

const auth = {
    namespaced: true,
    state: {
        goods: [],
        loading: false,
        chosenItem: {},
    },

    mutations: {
        SET_GOODS(state, payload) {
            state.goods = payload
        },

        SET_LOADING(state, payload) {
            state.loading = payload;
        },

        SET_CHOSEN_ITEM(state, payload) {
            state.chosenItem = payload;
        },

    },

    actions: {
        async UPDATE_GOODS({ commit }) {
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/goods`);
                commit("SET_GOODS", data);
                return data
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        async loadSingleGood({commit}, id){
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/goods?id=${id}`)
                return data
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        async CREATE_NEW_GOOD({commit}, good){
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.post(`https://unidan.webink.site/wp-json/ud/v1/goods`, good);
                commit("SET_GOODS", data);
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        async DELETE_GOODS({ commit }, id) {
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/goods?id=${id}`);
                commit("SET_GOODS", data);
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        }
    },

    getters: {
        GET_GOODS(state) {
            return state.goods
        },
        GET_LOADING(state) {
            return state.loading;
        },
        GET_CHOSEN_ITEM(state) {
            return state.chosenItem;
        }
    }
}

export default auth


