import axios from 'axios'
import Vue from "vue";

const parts = {
    namespaced: true,
    state: {
        loading: false,
        parts: [],
        singlePart: null
    },

    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_PARTS(state, parts){
            state.parts = parts
        },
        SET_SINGLE_PART(state, singlePart){
            state.singlePart = singlePart
        }
    },
    actions: {
        async loadParts({commit}){
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/parts`)
                commit("SET_PARTS", data)
                return data
            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async addNewPart({ commit, dispatch }, part){
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.post(`https://unidan.webink.site/wp-json/ud/v1/parts`, part)
                dispatch('loadParts')
            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async deletePart({ commit }, id){
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/parts?id=${id}`)
                return data
            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadSinglePart({commit}, id){
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/parts?id=${id}`)
                commit("SET_SINGLE_PART", data)
            } catch (error) {
                console.log(error)
            }
        },
        async updateSinglePart({commit, dispatch}, singlePart){
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.put(`https://unidan.webink.site/wp-json/ud/v1/parts`, singlePart)
                console.log(data)
                dispatch('loadParts')
            } catch (error) {
                console.log(error)
            }
        }
    },
    getters: {
        getLoading(state){
            return state.loading
        },
        getParts(state){
            return state.parts
        },
        getSinglePart(state){
            return state.singlePart
        }
    }
}

export default parts


