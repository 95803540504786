<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="mb-8 mt-4 d-flex align-center justify-space-between">
          <div class="text-h5 font-weight-medium">
            Техпроцесс
          </div>
          <router-link tag="a" to="/new-process">
            <v-btn dark color="primary" class="ml-4" rounded>
              Добавить
            </v-btn>
          </router-link>
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-select class="mr-4" :items="items" label="Фильтры" solo hide-details />
          <v-text-field placeholder="Поиск" solo append-icon="mdi-magnify" hide-details />
        </div>

        <v-data-table :headers="headers" :loading="GET_LOADING" :items="items" :items-per-page="5" class="elevation-1">
          <template v-slot:item.id="{ item }">
            <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>{{item.id}}</v-chip>
          </template>
          <template v-slot:item.description="{ item }">
            <p v-html="item.description"></p>
          </template>


          <template v-slot:item.actions="{ item }">
            <v-btn fab color="grey darken-3" x-small text @click="openTechCard">
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn @click="DELETE_TECH_PROCESS(item.id)" fab dark color="red" x-small text>
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from "vuex";

  export default {
    name: "TechProcess",

    props: {
      id: {
        type: Number,
        default: null,
      },
    },

    data() {
      return {
        headers: [{
            text: "№",
            value: "id"
          },
          {
            text: "Дата",
            value: "date"
          },
          {
            text: "Техпроцесс",
            value: "title"
          },
          {
            text: "Обьем производства",
            value: "description"
          },
          {
            text: "Марка",
            value: "mark"
          },
          {
            text: "Комментарий",
            value: "comment"
          },
          {
            text: "Действия",
            value: "actions"
          },
        ],
        items: [],
      };
    },
    computed: {
      ...mapGetters({
        GET_LOADING: "techProcess/GET_LOADING",
      }),
    },

    async created() {
      this.items = await this.UPDATE_TECH_PROCESS();
    },

    methods: {
      ...mapMutations({
        SET_CHOSEN_ITEM: "techProcess/SET_CHOSEN_ITEM",
      }),

      ...mapActions({
        UPDATE_TECH_PROCESS: "techProcess/UPDATE_TECH_PROCESS",
        DELETE_TECH_PROCESS: "techProcess/DELETE_TECH_PROCESS",
      }),

      openTechProcess(event, {
        item
      }) {
        this.SET_CHOSEN_ITEM(item);
        this.$router.push(`/tech-process/card/${item.id}`);
      },
    },
  };
</script>