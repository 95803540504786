<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="mb-8 mt-4 d-flex align-center justify-space-between">
          <div class="text-h5 font-weight-medium">
            Техоперации
          </div>
          <router-link tag="a" to="/new-operation">
            <v-btn dark color="primary" class="ml-4" rounded>
              Добавить
            </v-btn>
          </router-link>
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <!-- <v-select class="mr-4" :items="items" label="Фильтры" solo hide-details /> -->
          <!-- <v-text-field placeholder="Поиск" solo append-icon="mdi-magnify" hide-details /> -->
        </div>

        <!-- <pre>{{GET_TECH_OPERATIONS}}</pre> -->

        <v-data-table :headers="headers" :items="GET_TECH_OPERATIONS" :loading="GET_LOADING" :items-per-page="5" class="elevation-1"
          @dblclick:row="openTechCard" disable-pagination hide-default-footer>
          <template v-slot:item.id="{ item }">
            <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>{{item.id}}</v-chip>
          </template>

          <template v-slot:item.techcard="{ item }">
            {{returnTechMapTitle(item.techcard)}}
          </template>

          


          <template v-slot:item.actions="{ item }">
            <v-btn fab color="grey darken-3" x-small text @click="openTechCard($event, {item})">
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <a :href="`https://unidan.webink.site/wp-json/ud/v1/techoperation/doc?id=${item.id}`" target="_blank">
              <v-btn fab color="grey darken-3" x-small text>
                <v-icon dark>
                  mdi-printer
                </v-icon>
              </v-btn>
            </a>
            <v-btn @click="DELETE_TECH_OPERATION(item.id)" fab dark color="red" x-small text>
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from "vuex";

  export default {
    name: "TechMaps",

    props: {
      id: {
        type: Number,
        default: null,
      },
    },

    data() {
      return {
        headers: [{
            text: "№",
            value: "id"
          },
          {
            text: "Название",
            value: "title"
          },
          {
            text: "Кол-во товаров",
            value: "goods_amount"
          },
          {
            text: "Техкарта",
            value: "techcard"
          },
          {
            text: "Товар",
            value: "techoperation_data.ready_good.good.title"
          },
          {
            text: "Действия",
            value: "actions"
          },
        ],
        items: [],
      };
    },

    computed: {
      ...mapGetters({
        GET_LOADING: "techOperations/GET_LOADING",
        GET_TECH_MAPS: "techMaps/GET_TECH_MAPS",
        GET_TECH_OPERATIONS: "techOperations/GET_TECH_OPERATIONS"
      }),
    },

    async created() {
      await this.UPDATE_TECH_MAPS();
      this.items = await this.UPDATE_TECH_OPERATION();
    },

    methods: {
      returnTechMapTitle(id){
        let name = this.GET_TECH_MAPS.find(item =>{
          return item.id === id
        })
        return name.title
      },
      getTechMapTitle(item){
        let title = this.GET_TECH_MAPS.find(map =>{
          return map.id == item.techcard
        })
        return title.title
      },
      ...mapActions({
        UPDATE_TECH_MAPS: "techMaps/UPDATE_TECH_MAPS",
        UPDATE_TECH_OPERATION: "techOperations/UPDATE_TECH_OPERATION",
        DELETE_TECH_OPERATION: "techOperations/DELETE_TECH_OPERATION"
      }),
      openTechCard(event, { item }) {
        this.$router.push(`/tech-operations/${item.id}`);
      }
    },
  };
</script>