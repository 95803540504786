var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"mb-8 mt-4 d-flex align-center justify-space-between"},[_c('div',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Товары ")]),_c('router-link',{attrs:{"tag":"a","to":"/new-good"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"dark":"","color":"primary","rounded":""}},[_vm._v(" Добавить ")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.brands,"item-text":"name","item-value":"name","placeholder":"Бредн","dense":"","flat":"","outlined":"","solo":"","hide-details":"","clearable":""},model:{value:(_vm.selectedBrand),callback:function ($$v) {_vm.selectedBrand=$$v},expression:"selectedBrand"}}),_c('v-text-field',{attrs:{"placeholder":"Поиск","dense":"","flat":"","outlined":"","solo":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.GET_LOADING,"items":_vm.getItems,"search":_vm.search,"footer-props":{'items-per-page-options':[10],'disable-items-per-page': true,}},on:{"dblclick:row":_vm.openGoodsCard},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"label":"","dark":"","text-color":"#36ac50","color":"#36ac501a","small":""}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.ostatok",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"label":"","dark":"","text-color":"#000","color":"#0000002e","small":""}},[_vm._v(" "+_vm._s(item.customfields.ostatok)+" "+_vm._s(item.customfields.eizm)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.customfields",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.customfields.marka.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","dark":"","color":"red","x-small":"","text":""},on:{"click":function($event){return _vm.DELETE_GOODS(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"py-8"})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }