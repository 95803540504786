<template>
  <v-container fluid>
    <v-row class="py-6">
      <v-col cols="8" class="d-flex align-center">
        <div class="text-h5 font-weight-medium">
            Приемки
          </div>
          <router-link tag="a" to="/new-priemka">
            <v-btn color="primary" class="ml-4" rounded>
              Добавить 
            </v-btn>
          </router-link>
      </v-col>
      <v-col class="d-flex align-center">
          <v-text-field class="ml-2" placeholder="Поиск" dense flat outlined solo  append-icon="mdi-magnify" hide-details />
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :loading="loading" class="elevation-2"
        :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}" >
            <template v-slot:item.date="{ item }">
                <span>{{formatDate(item.date)}}</span>
            </template>
            <template v-slot:item.contragent="{ item }">
                <span>{{getAgent(item.contragent)}}</span>
            </template>
            <template v-slot:item.description="{ item }">
                <span v-html="item.description"></span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn fab color="grey darken-3" x-small text :to="`/priemki/${item.id}`">
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn @click="deleteItem(item.id)" fab dark color="red" x-small text>
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>

          <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from "vuex"
import formatDate from '../../mixins/formatDate'

export default {
    mixins: [formatDate],
    data() {
        return {
            agents: [],
            items: [],
            headers: [
                {
                    text: "Название",
                    value: "title"
                },
                {
                    text: "Описание",
                    value: "description"
                },
                {
                    text: "Контрагент",
                    value: "contragent"
                },
                {
                    text: "Дата",
                    value: "date"
                },
                {
                    text: "Действия",
                    value: "actions"
                },
            ]
        }
    },
    computed: {
      ...mapGetters({
        loading: 'global/getLoading'
      }),
    },
    methods: {
        ...mapActions({
            LOAD_GLOBAL: 'global/LOAD_GLOBAL',
            loadAgents: "contragents/loadAgents",
            DELETE_PRIEMKA: 'global/DELETE_PRIEMKA'
        }),
        getAgent(id){
          let res = this.agents.find(item => {
            return item.id === id
          })
          if(res){
            return res.title
          }
        },
        async deleteItem(id){
          await this.DELETE_PRIEMKA({id, type: 'priemki'}).then(res =>{
            this.items = res
          })
        }
    },
    async created(){
        this.items = await this.LOAD_GLOBAL('priemki')
        this.agents = await this.loadAgents()
    }
    // async created() {
    //     this.items = await this.loadParts()
    // }
}
</script>