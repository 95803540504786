<template>
        <v-container style="height:100vh;" class="d-flex align-center">
            <v-row justify="center">
                <v-col cols="12" sm="12" md="5" lg="5">
                    <v-card ref="form" class="pa-10 text-center elevation-2" rounded="lg">
                        <img src="../assets/logo.svg" style="height: 60px;">
                        <div class="text-h5 mb-8">Вход в личный кабинет</div>
                        <v-text-field flat outlined solo placeholder="Логин" prepend-inner-icon="mdi-account-outline" v-model="name" />
                        <v-text-field type="password" flat outlined solo placeholder="Пароль" prepend-inner-icon="mdi-lock-outline" v-model="pass"></v-text-field>
                        
                        <v-btn rounded dark color="primary" class="ml-4 px-12" x-large @click="login" :loading="load">Войти</v-btn>

                        <v-alert v-if="loginError" text dense type="error" class="mt-4">Ошибка! Неверный логин или пароль</v-alert>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

    export default{
        data(){
            return{
                name: '',
                pass: '',
                load: false
            }
        },
        computed: {
            ...mapGetters({
                loginError: "auth/getLoginErr",
                user: "auth/getAuthenticated"
            })
        },
        methods: {
            ...mapActions({
                    AUTH_REQUEST: "auth/AUTH_REQUEST",
            }),
            login(){
                this.load = true

                let form = {
                    username: this.name,
                    password: this.pass,
                };

                this.AUTH_REQUEST(form).then(() => {
                        this.load = false
                        if(this.loginError){
                            return
                        }else{
                            this.$router.replace("/");
                        }
                    });
            }
        }
    }
</script>
