import axios from 'axios'
import Vue from "vue";

const brands = {
    namespaced: true,
    state: {
        loading: false,
        brands: []
    },
    mutations: {
        SET_LOADING(state, payload){
            state.loading = payload
        },
        SET_BRANDS(state, brands){
            state.brands = brands
        }
    },
    actions: {
        async LOAD_BRANDS({commit}){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.get(`https://unidan.webink.site/wp-json/ud/v1/goods/categories?type=products_manufacture`)
                commit("SET_BRANDS", data)
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async DELETE_BRAND({commit}, brand){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/goods/categories`,  {data: brand})
                commit("SET_BRANDS", data)
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async CREATE_BRAND({commit}, brand){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.post(`https://unidan.webink.site/wp-json/ud/v1/goods/categories?type=products_manufacture`, brand)
                commit("SET_BRANDS", data)
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        }
    },
    getters: {
        getLoading(state){
            return state.loading
        },
        getBrands(state){
            return state.brands
        }
    }
}

export default brands


