<template>
        <v-container v-if="GET_CHOSEN_ITEM">
            <!-- <pre>{{GET_CHOSEN_ITEM}}</pre> -->
            <v-row>
                <v-col cols="12" lg="12">
                    <v-btn to="/" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                    <div class=" mt-4  mb-8">
                        <div class="text-h6 font-weight-medium mb-0">
                            Технологическая операция
                            <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small> № {{GET_CHOSEN_ITEM.id}}</v-chip> 
                        </div>
                        <div class="text-body-2 font-weight-medium grey--text mb-2">от {{ formatDate(GET_CHOSEN_ITEM.date)}}</div>
                        
                         <div class="text-h6 mb-3 font-weight-medium primary--text">{{GET_CHOSEN_ITEM.title}}</div>

                         <v-row>
                            <v-col cols="3" class="d-flex">
                                <v-icon small color="black" class="mr-4">mdi-archive</v-icon>
                                <div class="text-body-2 grey--text mb-1">
                                    Готовый продукт: <br>
                                    <span class="black--text">{{GET_CHOSEN_ITEM.techoperation_data.ready_good.good.title}}</span>
                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex">
                                <v-icon small color="black" class="mr-4">mdi-weight</v-icon>
                                <div class="text-body-2 grey--text mb-1">
                                    Объем: <br>
                                    <span class="black--text">{{GET_CHOSEN_ITEM.techoperation_data.ready_good.amount}} {{GET_CHOSEN_ITEM.techoperation_data.ready_good.good.customfields.eizm}}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="GET_TECH_MAPS.length">
                <v-col cols="12" lg="10" class="pt-0">
                    <div class="text-h6 mb-2 font-weight-medium">Материалы:</div>
                    <v-simple-table class="pb-0" style="border:1px solid #0000001f;">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>Кол-во сырья</th>
                                <th>Остаток сырья</th>
                                <th>Партия сырья</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in GET_CHOSEN_ITEM.techoperation_data.parts" :key="i">
                                <td>{{ item.name.title }}</td>
                                <td><v-text-field dense flat outlined solo label="Кол-во" hide-details v-model="item.amount" class="py-2" :suffix="item.name.customfields.eizm" disabled /></td>
                                <td><v-text-field dense flat outlined solo label="Кол-во" hide-details v-model="item.name.customfields.ostatok" class="py-2" :suffix="item.name.customfields.eizm" disabled /></td>
                                <td><v-text-field dense flat outlined solo label="Номер партии" hide-details v-model="item.consignment" class="py-2" /></td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <div class="mt-6 text-right">
                        <v-btn dark color="grey darken-2" text class="ml-4">Отмена</v-btn>
                        <v-btn dark color="primary" class="ml-2" rounded @click="updateTechCard" :loading="load">Обновить</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import formatDate from '../../mixins/formatDate'

export default {
    mixins: [formatDate],
    props: ["id"],
    data(){
        return{
            load: false
        }
    },
    name: "TechOperationCard",
    methods: {
        ...mapActions({
            GET_SINGLE_OPERATION: "techOperations/GET_SINGLE_OPERATION"
        }),
        getTechMapTitle(item){
            console.log(item.techcard)
            let title = this.GET_TECH_MAPS.find(map =>{
            return map.id == item.techcard
        })
            return title.title
        },
        updateTechCard(){
            this.load = true
            this.$store.dispatch('techOperations/UPDATE_CHOSEN', this.GET_CHOSEN_ITEM).then((res)=>{
                this.load = false
                this.$router.replace('/')
            })
        }
    },
    async created() {
      await this.GET_SINGLE_OPERATION(this.id);
    },
    computed: {
        ...mapGetters({
            GET_CHOSEN_ITEM: "techOperations/GET_CHOSEN_ITEM",
            GET_TECH_MAPS: "techMaps/GET_TECH_MAPS"
        }),
    },
};
</script>
