<template>
    <v-container fluid>
        <v-row class="py-6">
            <v-col cols="8" class="d-flex align-center">
                <div class="text-h5 font-weight-medium">
                    Группы
                </div>
                <v-btn color="primary" dark class="ml-4" rounded @click="dialog = true">
                    Добавить группу
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-data-table :headers="headers" :items="groups" :loading="loading" class="elevation-2"
                    :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}">
                    <template v-slot:item.id="{ item }">
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>
                            {{item.id}}</v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="deleteGroup(item.id)" fab dark color="red" x-small text>
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <template #footer.prepend>
                        <v-spacer />
                        <div class="py-8"></div>
                    </template>
                </v-data-table>


                <!-- добавить бренд -->

                <v-dialog v-model="dialog" width="450">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <div class="text-h5">
                                Добавление группы
                            </div>
                        </v-toolbar>
                        <div class="px-6 py-8">
                            <div class="text-body-2">Название</div>
                            <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details v-model="name" />
                        </div>

                        <v-divider />

                        <v-card-actions>
                            <v-spacer />
                            <v-btn color="primary" text @click="dialog = false">
                                Отмена
                            </v-btn>
                            <v-btn color="primary" dark :loading="loading" @click="addGroup">
                                Добавить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>



            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {
        mapActions,
        mapGetters
    } from "vuex"
    import formatDate from '../../mixins/formatDate'

    export default {
        mixins: [formatDate],
        data() {
            return {
                dialog: false,
                name: '',
                headers: [{
                        text: "ID",
                        value: "id"
                    },
                    {
                        text: "Название",
                        value: "name"
                    },
                    {
                        text: "Действия",
                        value: "actions"
                    },
                ]
            }
        },
        computed: {
            ...mapGetters({
                loading: 'groups/getLoading',
                groups: 'groups/getGroups'
            })
        },
        methods: {
            ...mapActions({
                LOAD_GROUPS: 'groups/LOAD_GROUPS',
                DELETE_GROUP: 'groups/DELETE_GROUP',
                CREATE_GROUP: 'groups/CREATE_GROUP'
            }),
            addGroup(){
                let groupItem = {
                    name: this.name,
                    type: 'gruppi'
                }
                this.CREATE_GROUP(groupItem).then(() => {
                    this.dialog = false
                })
            },
            deleteGroup(id){
                this.DELETE_GROUP({id: id, type: 'gruppi'})   
            }
        },
        async created() {
            await this.LOAD_GROUPS()
        }
    }
</script>