<template>
  <v-navigation-drawer v-model="drawer" app clipped v-if="user">
    <v-list-item>
      <div class="d-flex align-center py-2">
        <v-avatar color="#36ac501a" size="38" class="mr-2">
          <span class="green--text text--darken-4 text-body-2 text-uppercase font-weight-medium">
            {{user.user_nicename[0]}}
          </span>
        </v-avatar>
       <div class="text-body-2 mb-0 font-weight-medium grey--text text--darken-2">
          {{ user.user_email }}
        </div>
      </div> 
    </v-list-item>

    <v-divider />
    <!-- NAV -->
    <v-list nav dense expand>
      <v-list-group 
          no-action
          :value="item.active"
          v-for="(item, index) in navList" :key="index"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"><v-icon class="mr-4" small>{{item.icon}}</v-icon>{{item.txt}}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item nav
          class="pl-10"
            v-for="(sub, i) in item.child"
            :key="i"
            link
            :to="sub.link"
          >
            <v-list-item-title>{{sub.txt}}</v-list-item-title>
          </v-list-item>
        </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>


<script>
import { mapGetters } from 'vuex';
import {EventBus} from "../helpers/eventBus";
  export default {
    name: "NavigationDrawer",
    computed: {
      ...mapGetters({
        user: "auth/getAuthenticated",
      })
    },
    data() {
      return {
        drawer: true,
        opened: [],
        navList: [
          {
            active: false,
            txt: 'ЗАКУПКИ (тест)',
            icon: 'mdi-store-plus',
            child: [
              {
                txt: 'Счета',
                link: '/bills'
              },
              {
                txt: 'Приемки',
                link: '/priemki'
              },
              {
                txt: 'Возвраты',
                link: '/refunds'
              }
            ]
          },
          {
            active: false,
            txt: 'ТОВАРЫ',
            icon: 'mdi-shopping',
            child: [
              {
                txt: 'Все товары',
                link: '/goods'
              },
              // {
              //   txt: 'Оприходывания',
              //   link: '/posting'
              // },
              // {
              //   txt: 'Списания',
              //   link: '/write-offs'
              // },
              {
                txt: 'Инвентаризация',
                link: '/inventory'
              },
              {
                txt: 'Материалы',
                link: '/parts'
              }
            ]
          },
          {
            active: true,
            txt: 'ПРОИЗВОДСТВО',
            icon: 'mdi-test-tube',
            child: [
              {
                txt: 'Техоперации',
                link: '/'
              },
              {
                txt: 'Техкарты',
                link: '/tech-maps'
                // добавить вложенность (все и их категории)
              },
              {
                txt: 'Техпроцесс',
                link: '/tech-process'
              }
            ]
          },
          {
            active: false,
            txt: 'СПРАВОЧНИКИ',
            icon: 'mdi-book',
            child: [
              {
                txt: 'Бренды',
                link: '/brands'
              },
              {
                txt: 'Категории техкарт',
                link: '/tech-maps-cats'
                // добавить вложенность (все и их категории)
              },
              {
                txt: 'Контрагенты',
                link: '/contragents'
              },
              {
                txt: 'Группы',
                link: '/groups'
              },
            ]
          }
        ]
      }
    },
    created() {
      EventBus.$on("toggleDrawer", () => {
        this.drawer = !this.drawer;
      });
    },
  };
</script>
