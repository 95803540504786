<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Новая техоперация</div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3">
                <div class="text-body-2">Техкарта</div>
                <v-select dense flat outlined class="mb-4" v-model="techcard" :items="GET_TECH_MAPS" item-value="id"
                    item-text="title" return-object placeholder="Техкарта" solo hide-details @change="autoSetTitle"></v-select>

                <div class="text-body-2">Итоговый продукт</div>
                <v-select dense flat outlined class="mb-4" v-model="good" 
                :items="techcard !== null ? techcard.goods : []" item-value="good.id"
                item-text="good.title" placeholder="Итоговый продукт" solo hide-details></v-select>
                    
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="title" />

                <div class="text-body-2">Номер</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите номер" hide-details
                    v-model="number" />
                <!-- <div class="text-body-2">Дата</div>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" dense flat outlined class="mb-4"
                            append-icon="mdi-calendar-blank-outline" readonly v-bind="attrs" v-on="on"
                            @click:append="menu = !menu" solo hide-details="auto"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable color="primary">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            ОТМЕНА
                        </v-btn>
                        <v-btn text color="primary" @click="updatePage">
                            ОК
                        </v-btn>
                    </v-date-picker>
                </v-menu> -->

                <div class="text-body-2">Обьем производства</div>
                <v-text-field type="number" suffix="м3" class="mb-4" dense flat outlined solo placeholder="1200"
                    hide-details v-model="goods_amount"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3" class="text-right ">
                <v-btn dark color="primary" :loading="loading" rounded class="ml-2" @click="newOperation">Добавить</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
    computed:{
        ...mapGetters({
            GET_TECH_MAPS: "techMaps/GET_TECH_MAPS",
            loading: "techOperations/GET_LOADING",
        })
    },
    data(){
        return{
            date: new Date(),
            menu: false,
            techcard: null,
            goods_amount: null,
            good: null,
            title: '',
            number: null
        }
    },
    methods: {
        autoSetTitle(){
            let res = this.GET_TECH_MAPS.find(i => i.id === this.techcard.id)
            this.title = `${res.title} - ${moment(new Date()).format('DD.MM.YYYY H:mm')}`
        },
        newOperation(){
            let operation ={
                title: this.title,
                date: this.date,
                number: parseInt(this.number),
                goods_amount: parseInt(this.goods_amount),
                good: this.good,
                techcard: this.techcard.id
            }
            this.$store.dispatch('techOperations/CREATE_NEW_OPERATION', operation).then((res) =>{
                this.$router.replace('/')
            })
        },
        updatePage(){
            this.$refs.menu.save(this.date)
        }
    }
}
</script>
