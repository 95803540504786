<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/contragents" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Новый контрагент</div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4">
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="form.title" />

                <div class="text-body-2">E-mail</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите e-mail" hide-details
                    v-model="form.email" />
                <div class="text-body-2">Телефон</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="+7" hide-details
                    v-model="form.phone" v-mask="'+7 ### ###-##-##'" />
                    <div class="text-body-2">Группа</div>
                <!-- <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите группу" hide-details
                    v-model="form.gruppa" /> -->
                    <v-select
                    class="mb-4"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    placeholder="Группа"
                    v-model="form.gruppa"
                    multiple
                    chips
                    dense flat outlined solo hide-details
                />
                <div class="text-body-2">Статус</div>
                <v-select
                    class="mb-4"
                    :items="statuses"
                    placeholder="Стаус"
                    v-model="form.status"
                    dense flat outlined solo hide-details
                />

                <div class="text-body-2">Комментарий</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите комментарий" hide-details
                    v-model="form.description" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" class="text-right ">
                <v-btn dark color="primary" rounded class="ml-2" @click="addPart" :loading="loading">Добавить</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data(){
        return{
            form: {
                title: '',
                description: '',
                email: '',
                gruppa: [],
                phone: '',
                status: null
            },
            statuses: [
                'Активный',
                'Новый',
                'Выслано предложение',
                'Переговоры',
                'Неактивный'
            ]
        }
    },
    computed: {
        ...mapGetters({
            loading: 'contragents/getLoading',
            groups: 'groups/getGroups'
        })
    },
    methods: {
        ...mapActions({
            ADD_NEW_AGENT: 'contragents/addNewAgent',
            LOAD_GROUPS: 'groups/LOAD_GROUPS'
        }),
        addPart(){
            this.form.gruppa.join()
            this.ADD_NEW_AGENT(this.form).then(() => {
                this.$router.replace('/contragents')
            })
        }
    },
    async created(){
        await this.LOAD_GROUPS()
    }
}
</script>
