<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/priemki" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-start">
                    <div class="text-h5 font-weight-medium">Новая приемка</div>
                    <v-btn dark color="primary" rounded class="ml-2" @click="createTechMap" :loading="GET_LOADING">Добавить приемку</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details v-model="title" />
                <div class="text-body-2">Описание</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details v-model="description" />
                <div class="text-body-2">Контрагент</div>
                <v-select dense flat outlined class="mb-4" v-model="contragent" :items="agents" item-value="id" item-text="title" placeholder="Контрагент" solo hide-details />
                
                <div class="text-body-2">Договор</div>
                 <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details v-model="dogovor" />
            </v-col>
            <v-col cols="6" >
                <v-card elevation="0" color="grey lighten-3" class="mt-4">
                    <v-tabs v-model="tab" background-color="grey lighten-3">
                        <v-tab>Продукция</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <div class="grey lighten-3 pa-4 text-end">
                                <v-simple-table class="mb-3" style="border:1px solid #0000001f;">
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Наименование</th>
                                            <th>Норма по техкарте</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(good, i) in materials" :key="i">
                                            <td><v-autocomplete :items="getParts" item-value="id" item-text="title" dense flat outlined solo hide-details class="my-4" 
                                            v-model="good.material" placeholder="Поиск по наименованию"></v-autocomplete></td>
                                            <td><v-text-field class="my-4" dense flat outlined solo placeholder="Введите объем" hide-details type="number" v-model="good.kolichestvo" :suffix="getSuffix(i)" /></td>
                                            <td><v-icon @click="materials.splice(i, 1)" v-show="materials.length > 1">mdi-close</v-icon></td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                <v-btn @click="materials.push({material: null,kolichestvo: null})" color="primary" small text><v-icon>mdi-plus</v-icon>добавить</v-btn>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    computed:{
        ...mapGetters({
            GET_TECH_MAPS: "techMaps/GET_TECH_MAPS",
            getParts: "parts/getParts",
            getGoods: "goods/GET_GOODS",
            GET_TECH_MAPS_CATS: 'techMaps/GET_TECH_MAPS_CATS',
            GET_LOADING: "global/getLoading",
        })
    },
    data(){
        return{
            agents: [],
            // selectedProduct: null,
            selectedProductWeight: null,
            tab: 0,
            materials: [
                {   
                    material: null,
                    kolichestvo: null
                }
            ],
            date: '',
            menu: false,
            contragent: null,
            dogovor: null,
            goods_amount: null,
            title: '',
            description: '',
            number: null,
            goods: [
                {   
                    name: null,
                    weight: null
                }
            ]
        }
    },
    async created(){
        this.agents = await this.loadAgents()
        await this.loadParts()
        await this.UPDATE_GOODS()
        await this.loadTechMapCats()
    },
    methods: {
        ...mapActions({
            loadAgents: "contragents/loadAgents",
            loadTechMapCats: 'techMaps/loadTechMapCats',
            UPDATE_GOODS: "goods/UPDATE_GOODS",
            loadParts: "parts/loadParts",
            UPDATE_TECH_PROCESS: "techProcess/UPDATE_TECH_PROCESS",
            CREATE_PRIEMKA: 'global/CREATE_PRIEMKA'
        }),
        async createTechMap(){
            let newMap = {
                title: this.title,
                description: this.description,
                type: 'priemki',
                contragent: this.contragent,
                dogovor: this.dogovor,
                goods: this.materials
            }

            await this.CREATE_PRIEMKA(newMap).then(() =>{
                this.$router.replace('/priemki')
            })
        },
        getSuffix(i){
            let res = this.getParts.find(item => { 
                return item.id == this.materials[i].name
            })
            if(res){
                return res.customfields.eizm
            }
            // return i
        },
        newOperation(){
            let operation ={
                title: this.title,
                date: this.date,
                number: parseInt(this.number),
                goods_amount: parseInt(this.goods_amount),
                techcard: this.techcard
            }
            this.$store.dispatch('techOperations/CREATE_NEW_OPERATION', operation).then((res) =>{

                this.$router.replace('/tech-operations')
            })
        },
        updatePage(){
            this.$refs.menu.save(this.date)
        }
    }
}
</script>
