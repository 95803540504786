import axios from 'axios'
import Vue from "vue";

const techOperations = {
    namespaced: true,
    state: {
        techOperations: [],
        loading: false,
        chosenItem: null,
    },

    mutations: {
        SET_TECH_MAPS(state, payload) {
            state.techOperations = payload
        },

        SET_LOADING(state, payload) {
            state.loading = payload;
        },

        SET_SINGLE_ITEM(state, payload) {
            state.chosenItem = payload;
        },

    },

    actions: {
        async GET_SINGLE_OPERATION({ commit }, id){
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/techoperation?id=${id}`)
                commit("SET_SINGLE_ITEM", data)
            } catch (error) {
                console.log(error)
            }
        },
        async UPDATE_CHOSEN({commit, dispatch}, operation){
            try {
                const { data } = await axios.put(`https://unidan.webink.site/wp-json/ud/v1/techoperation`, operation);
                dispatch('GET_SINGLE_OPERATION', operation.id)

            } catch (error) {
                console.log(error)
            }
        },
        async CREATE_NEW_OPERATION({commit}, operation){
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.post(
                    `https://unidan.webink.site/wp-json/ud/v1/techoperation`, operation
                );
                return data

            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async UPDATE_TECH_OPERATION({ commit }) {
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.get(
                    `https://unidan.webink.site/wp-json/ud/v1/techoperation`
                );

                commit("SET_TECH_MAPS", data);

                return data
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        DELETE_TECH_OPERATION({ dispatch }, id) {
            axios.delete(`https://unidan.webink.site/wp-json/ud/v1/techoperation?id=${id}`,)
                .then(res => {
                    dispatch("UPDATE_TECH_OPERATION")
                })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        }
    },

    getters: {
        GET_TECH_MAPS(state) {
            return state.techMaps
        },
        GET_LOADING(state) {
            return state.loading;
        },
        GET_TECH_OPERATIONS(state){
            return state.techOperations
        },
        GET_CHOSEN_ITEM(state) {
            return state.chosenItem
        },
    }
}

export default techOperations


