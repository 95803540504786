<template>
    <v-container fluid>
        <v-row class="py-6">
            <v-col cols="8" class="d-flex align-center">
                <div class="text-h5 font-weight-medium">
                    Категории техкарт
                </div>
                <v-btn color="primary" dark class="ml-4" rounded @click="dialog = true">
                    Добавить категорию
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-data-table :headers="headers" :items="cats" :loading="loading" class="elevation-2"
                    :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}">
                    <template v-slot:item.id="{ item }">
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>
                            {{item.id}}</v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="deleteCat(item.id)" fab dark color="red" x-small text>
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <template #footer.prepend>
                        <v-spacer />
                        <div class="py-8"></div>
                    </template>
                </v-data-table>


                <!-- добавить бренд -->

                <v-dialog v-model="dialog" width="450">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <div class="text-h5">
                                Добавление бренда
                            </div>
                        </v-toolbar>
                        <div class="px-6 py-8">
                            <div class="text-body-2">Название</div>
                            <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details v-model="name" />
                        </div>

                        <v-divider />

                        <v-card-actions>
                            <v-spacer />
                            <v-btn color="primary" text @click="dialog = false">
                                Отмена
                            </v-btn>
                            <v-btn color="primary" dark :loading="loading" @click="addCat">
                                Добавить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>



            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import formatDate from '../../mixins/formatDate'

    export default {
        mixins: [formatDate],
        data() {
            return {
                dialog: false,
                name: '',
                headers: [{
                        text: "ID",
                        value: "id"
                    },
                    {
                        text: "Название",
                        value: "name"
                    },
                    {
                        text: "Действия",
                        value: "actions"
                    },
                ]
            }
        },
        computed: {
            ...mapGetters({
                loading: 'maps_cats/getLoading',
                cats: 'maps_cats/getCats'
            })
        },
        methods: {
            ...mapActions({
                LOAD_CATS: 'maps_cats/LOAD_CATS',
                DELETE_CAT: 'maps_cats/DELETE_CAT',
                CREATE_CAT: 'maps_cats/CREATE_CAT'
            }),
            addCat(){
                let catItem = {
                    name: this.name,
                    type: 'techcart_category'
                }
                this.CREATE_CAT(catItem).then(() => {
                    this.dialog = false
                })
            },
            deleteCat(id){
                this.DELETE_CAT({id: id, type: 'techcart_category'})
                
            }
        },
        async created() {
            await this.LOAD_CATS()
        }
    }
</script>