<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/parts" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Новый материал</div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3">
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="title" />

                <div class="text-body-2">Описание</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details
                    v-model="description" />
                <div class="text-body-2">Остаток</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите остаток" hide-details
                    v-model="ostatok" />
                <div class="text-body-2">Единица измерения</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="кг/л..." hide-details
                    v-model="eizm" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3" class="text-right ">
                <v-btn dark color="primary" rounded class="ml-2" @click="addPart" :loading="loading">Добавить</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data(){
        return{
            title: '',
            description: '',
            ostatok: '',
            eizm: ''
        }
    },
    computed: {
        ...mapGetters({
            loading: 'parts/getLoading'
        })
    },
    methods: {
        ...mapActions({
            ADD_NEW_PART: 'parts/addNewPart'
        }),
        addPart(){
            let part ={
                title: this.title,
                description: this.description,
                customfields: {
                    ostatok: this.ostatok,
                    eizm: this.eizm
                }
            }
            this.ADD_NEW_PART(part).then(() => {
                this.$router.replace('/parts')
            })
        }
    }
}
</script>
