<template>
    <v-container  v-if="item">
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/priemki" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-start">
                    <div class="text-h5 font-weight-medium">Приемка:
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold ml-2" small> №
                            {{item.id}}</v-chip></div>
                    <v-btn dark color="primary" rounded class="ml-2" @click="updateItem" :loading="GET_LOADING">Обновить приемку</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details v-model="item.title" />
                <div class="text-body-2">Описание</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details v-model="item.description" />
                <div class="text-body-2">Контрагент</div>
                <v-select dense flat outlined class="mb-4" v-model="item.contragent" :items="agents" item-value="id" item-text="title" placeholder="Контрагент" solo hide-details />
                
                <div class="text-body-2">Договор</div>
                 <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details v-model="item.dogovor" />

                <div class="text-body-2">Дата</div>
                 <!-- <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите дату" hide-details v-model="item.date" /> -->
                 <!-- <pre>{{item.date}}</pre> -->
                 <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="item.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="normalDate(item.date)"
                        label="Picker in menu"
                        dense flat outlined solo
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="item.date"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(item.date)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
             <v-col cols="6" >
                <v-card elevation="0" color="grey lighten-3" class="mt-4">
                    <v-tabs v-model="tab" background-color="grey lighten-3">
                        <v-tab>Продукция</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <div class="grey lighten-3 pa-4 text-end">
                                <v-simple-table class="mb-3" style="border:1px solid #0000001f;">
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Наименование</th>
                                            <th>Норма по техкарте</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(good, i) in item.goods" :key="i">
                                            <td><v-autocomplete :items="getParts" item-value="id" item-text="title" dense flat outlined solo hide-details class="my-4" 
                                            v-model="good.material" placeholder="Поиск по наименованию"></v-autocomplete></td>
                                            <td><v-text-field class="my-4" dense flat outlined solo placeholder="Введите объем" hide-details type="number" v-model="good.kolichestvo" :suffix="getSuffix(i)" /></td>
                                            <td><v-icon @click="item.goods.splice(i, 1)" v-show="item.goods.length > 1">mdi-close</v-icon></td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                <v-btn @click="item.goods.push({material: null,kolichestvo: null})" color="primary" small text><v-icon>mdi-plus</v-icon>добавить</v-btn>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
// import formatDate from '@/mixins/formatDate'
import moment from 'moment'

export default {
    // mixins: [formatDate],
    props: ["id"],
    data(){
        return {
            menu: false,
            item: null,
            agents: [],
            tab: 0
        }
    },
    computed:{
        ...mapGetters({
            getParts: "parts/getParts",
            GET_LOADING: "global/getLoading",
        })
    },
    async created(){
        this.item = await this.loadSingle({id: this.id, type: 'priemki'})
        await this.loadParts()
        this.item.date = this.formatDate(this.item.date)
        this.agents = await this.loadAgents()
    },
    methods: {
        normalDate(d){
            return moment(d).format('DD.MM.YYYY')
        },
        formatDate(d){
            return moment(d).format('YYYY-MM-DD')
        },
        ...mapActions({
            loadAgents: "contragents/loadAgents",
            loadSingle: "global/loadSingle",
            loadParts: "parts/loadParts",
            UPDATE_ITEM: 'global/UPDATE_ITEM'
        }),
        getSuffix(i){
            let res = this.getParts.find(item => { 
                return item.id == this.item.goods[i].name
            })
            if(res){
                return res.customfields.eizm
            }
        },
        async updateItem(){
            this.item.date = moment(this.item.date).format()
            await this.UPDATE_ITEM(this.item).then(() =>{
                this.$router.replace('/priemki')
            })
        }
//         async createTechMap(){
//             let newMap = {
//                 title: this.title,
//                 description: this.description,
//                 type: 'priemki',
//                 contragent: this.contragent,
//                 dogovor: this.dogovor,
//                 goods: this.materials
//             }

//             await this.CREATE_PRIEMKA(newMap).then(() =>{
//                 this.$router.replace('/priemki')
//             })
//         },
//        
//         newOperation(){
//             let operation ={
//                 title: this.title,
//                 date: this.date,
//                 number: parseInt(this.number),
//                 goods_amount: parseInt(this.goods_amount),
//                 techcard: this.techcard
//             }
//             this.$store.dispatch('techOperations/CREATE_NEW_OPERATION', operation).then((res) =>{

//                 this.$router.replace('/tech-operations')
//             })
//         }
    }
}
</script>
