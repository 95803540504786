import axios from 'axios'
import Vue from "vue";

const auth = {
    namespaced: true,
    state: {
        techProcess: [],
        loading: false,
        chosenItem: {},
    },

    mutations: {
        SET_TECH_PROCESS(state, payload) {
            state.techProcess = payload
        },

        SET_LOADING(state, payload) {
            state.loading = payload;
        },

        SET_CHOSEN_ITEM(state, payload) {
            state.chosenItem = payload;
        },

    },

    actions: {
        async UPDATE_TECH_PROCESS({ commit }) {
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.get(
                    `https://unidan.webink.site/wp-json/ud/v1/techprocess`
                );
                
                commit("SET_TECH_PROCESS", data);

                return data
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        DELETE_TECH_PROCESS({ commit, state }, id) {
            axios.delete(`https://unidan.webink.site/wp-json/ud/v1/techprocess?id=${id}`,)
                .then(res => {
                    commit("SET_TECH_PROCESS", res)
                })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        }
    },

    getters: {
        GET_TECH_PROCESS(state) {
            return state.techProcess
        },
        GET_LOADING(state) {
            return state.loading;
        },
        GET_CHOSEN_ITEM(state) {
            return state.chosenItem;
        }
    }
}

export default auth


