import axios from 'axios'
import Vue from "vue";

const groups = {
    namespaced: true,
    state: {
        loading: false,
        groups: []
    },
    mutations: {
        SET_LOADING(state, payload){
            state.loading = payload
        },
        SET_GROUPS(state, groups){
            state.groups = groups
        }
    },
    actions: {
        async LOAD_GROUPS({commit}){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.get(`https://unidan.webink.site/wp-json/ud/v1/contractor/categories?type=gruppi`)
                commit("SET_GROUPS", data)
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async DELETE_GROUP({commit}, group){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/goods/categories`,  {data: group})
                commit("SET_GROUPS", data)
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async CREATE_GROUP({commit}, group){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.post(`https://unidan.webink.site/wp-json/ud/v1/contractor/categories?type=gruppi`, group)
                commit("SET_GROUPS", data)
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        }
    },
    getters: {
        getLoading(state){
            return state.loading
        },
        getGroups(state){
            return state.groups
        }
    }
}

export default groups


