import axios from 'axios'
import Vue from "vue";

const global = {
    namespaced: true,
    state: {
        loading: false
    },
    mutations: {
        SET_LOADING(state, payload){
            state.loading = payload
        }
    },
    actions: {
        async LOAD_GLOBAL({commit}, type){
            commit("SET_LOADING", true);
            try {
                const { data } =  await axios.get(`https://unidan.webink.site/wp-json/ud/v1/posts?type=${type}`)
                return data
            } catch {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async CREATE_PRIEMKA({commit}, payload){
            commit("SET_LOADING", true);
            try {
                const { data } =  await axios.post(`https://unidan.webink.site/wp-json/ud/v1/posts`, payload)
                return data
            } catch(error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadSingle({commit}, payload){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.get(`https://unidan.webink.site/wp-json/ud/v1/posts?id=${payload.id}&type=${payload.type}`)
                return data
            } catch(error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async UPDATE_ITEM({commit}, payload){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.put(`https://unidan.webink.site/wp-json/ud/v1/posts`, payload)
                return data
            } catch(error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async DELETE_PRIEMKA({commit}, payload){
            commit("SET_LOADING", true)
            try {
                const { data } =  await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/posts?id=${payload.id}&type=${payload.type}`)
                return data
            } catch(error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false)
            }
        }
    },
    getters: {
        getLoading(state){
            return state.loading
        }
    }
}

export default global


