import moment from "moment";

export default {
  methods:{
    formatDate(d) {
      return moment(d).format('DD.MM.YYYY')
    }
  }
}

