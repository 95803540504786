import axios from 'axios'
import Vue from "vue";

const contragents = {
    namespaced: true,
    state: {
        loading: false,
        contragents: [],
        singleAgent: null
    },

    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_AGENTS(state, contragents){
            state.contragents = contragents
        },
        // SET_SINGLE_AGENT(state, singleAgent){
        //     state.singleAgent = singleAgent
        // }
    },
    actions: {
        async loadAgents({commit}){
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/contractor`)
                commit("SET_AGENTS", data)
                return data
            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async addNewAgent({ commit, dispatch }, agent){
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.post(`https://unidan.webink.site/wp-json/ud/v1/contractor`, agent)
                dispatch('loadAgents')
            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async deleteAgent({ commit }, id){
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/contractor?id=${id}`)
                return data
            } catch (error) {
                console.log(error)
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadSingleAgent({ }, id){
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/contractor?id=${id}`)
                return data
            } catch (error) {
                console.log(error)
            }
        },
        async updateSingleAgent({commit, dispatch}, singleAgent){
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.put(`https://unidan.webink.site/wp-json/ud/v1/contractor`, singleAgent)
                dispatch('loadAgents')
            } catch (error) {
                console.log(error)
            }
        }
    },
    getters: {
        getLoading(state){
            return state.loading
        },
        getAgents(state){
            return state.contragents
        },
        getSingleAgent(state){
            return state.singleAgent
        }
    }
}

export default contragents
