<template>
  <v-container fluid>
    <v-row class="py-6">
      <v-col cols="7" class="d-flex align-center">
        <div class="text-h5 font-weight-medium">
            Материалы
          </div>
          <router-link tag="a" to="/new-part">
            <v-btn dark color="primary" class="ml-4" rounded>
              Добавить материал
            </v-btn>
          </router-link>
      </v-col>
      <v-col class="d-flex align-center">
        <v-switch class="mr-6 mt-0" inset v-model="instock" label="В налличии" hide-details></v-switch>
          <v-text-field class="ml-2" placeholder="Поиск" dense flat outlined solo  append-icon="mdi-magnify" hide-details v-model="search" />
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="getItems" :loading="loading" class="elevation-2" :search="search"
        :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}" @dblclick:row="openPartCard">
            <template v-slot:item.customfields.ostatok="{ item }">
              <div v-if="item.customfields.ostatok">
                <v-chip v-if="item.customfields.ostatok > 0" label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>{{item.customfields.ostatok}}</v-chip>
                <v-chip label dark text-color="red" color="#f4433640" class="font-weight-bold" small v-else>{{item.customfields.ostatok}}</v-chip>
              </div>
            </template>
          <template v-slot:item.actions="{ item }">
            <v-btn fab color="grey darken-3" x-small text @click="openPartCard($event, {item})" >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn  fab dark color="red" x-small text @click="deletePartItem(item.id)">
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </template> 
          <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            instock: false,
            search: '',
            items: [],
            headers: [
                {
                    text: "Название",
                    value: "title"
                },
                {
                    text: "Остаток",
                    value: "customfields.ostatok"
                },
                {
                    text: "Единица измерения",
                    value: "customfields.eizm"
                },
                {
                    text: "Описание",
                    value: "description"
                },
                {
                    text: "Действия",
                    value: "actions"
                },
            ]
        }
    },
    computed: {
      ...mapGetters({
        loading: 'parts/getLoading'
      }),
      getItems(){
        if(this.instock){
          return this.items.filter(i => i.customfields.ostatok > 0)
        }
        return this.items
      }
    },
    methods: {
        async deletePartItem(id){
            this.items = await this.deletePart(id)
        },
        ...mapActions({
            loadParts: "parts/loadParts",
            deletePart: "parts/deletePart"
        }),
        openPartCard(event, { item }){
          this.$router.push(`/parts/${item.id}`);
        }
    },
    async created() {
        this.items = await this.loadParts()
    }
}
</script>