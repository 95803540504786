<template>
    <v-container>
        <v-row v-if="goodItem">
            <v-col cols="12" lg="12">
                <v-btn to="/goods" elevation="0" color="grey lighten-3" rounded exact-path small>
                    <v-icon x-small>mdi-arrow-left</v-icon>
                    Назад
                </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Товар: <span class="primary--text">{{goodItem.title}}</span>
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold ml-2" small> №
                            {{goodItem.id}}</v-chip>
                    </div>
                    <div class="text-body-2 grey--text mb-1">{{goodItem.description}}</div>
                </div>
                <v-row>
                    <v-col cols="2" class="d-flex">
                        <v-icon small color="black" class="mr-4">mdi-storefront</v-icon>
                        <div class="text-body-2 grey--text mb-1">
                            Остаток: <br>
                            <span class="black--text">
                                {{goodItem.customfields.ostatok}}
                                {{goodItem.customfields.eizm}}
                            </span>
                        </div>
                    </v-col>
                    <v-col cols="2" class="d-flex">
                        <v-icon small color="black" class="mr-4">mdi-decagram</v-icon>
                        <div class="text-body-2 grey--text mb-1">
                            Бренд: <br>
                            <span class="black--text">
                                {{goodItem.customfields.marka.name}}
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="goodItem">
            <v-col cols="12">
                <div class="text-h5 font-weight-medium mb-4 mt-4">История техопераций</div>
                 <v-data-table :headers="headers" :loading="loading" :items="goodItem.ostatok_history" class="elevation-1"  :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}">
                    <template v-slot:item.techoperation="{ item }">
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small v-if="item.techoperation.id">
                        {{item.techoperation.id}}
                        </v-chip>
                        <v-chip label dark text-color="red" color="#f4433640" class="font-weight-bold" small v-else>Удаление</v-chip>
                    </template>

                    <template v-slot:item.date="{ item }">
                        <span>{{formatDate(item.date)}}</span>
                    </template>

                    <template v-slot:item.change_value="{ item }">
                        <v-chip label dark 
                        :text-color="item.change_value[0] === '+' ? '#36ac50': 'red' " 
                        :color="item.change_value[0] === '+' ? '#36ac501a': '#f4433640' " 
                        class="font-weight-bold" small>
                            {{item.change_value}}
                        </v-chip>
                    </template>

                    <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else class="py-6">
            <v-col cols="4" offset="4">
                <div class="text-center text-body-1">Загрузка данных</div>
                <v-progress-linear
                    indeterminate
                    color="primary"
                    ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import formatDate from '@/mixins/formatDate'

export default {
    props: ["id"],
    mixins: [formatDate],
    computed:{
        ...mapGetters({
            loading: "goods/GET_LOADING",
        })
    },
    data(){
        return{
            goodItem: null,
            headers: [
                {
                    text: "ID техоперации",
                    value: "techoperation"
                },
                {
                    text: "Название техоперации",
                    value: "techoperation.name"
                },
                {
                    text: "Тип",
                    value: "description"
                },
                {
                    text: "Измененное значение",
                    value: "change_value"
                },
                {
                    text: "Дата",
                    value: "date"
                },
            ]
        }
    },
    async created(){
        this.goodItem = await this.loadSingleGood(this.id)
    },
    methods: {
        ...mapActions({
            loadSingleGood: 'goods/loadSingleGood'
        })
    }
}
</script>
