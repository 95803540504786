<template>
    <v-container v-if="getSingleMap">
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/tech-maps" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mt-4 d-flex align-center justify-start">
                    <div class="text-h5 font-weight-medium">Техкарта <span class="primary--text">{{getSingleMap.title}}</span></div>
                    <!-- <v-btn dark color="primary" rounded class="ml-2" @click="createTechMap" :loading="GET_LOADING">Добавить техкарту</v-btn> -->
                </div>
                <div class="text-body-2 font-weight-medium grey--text mb-4">от {{formatDate(getSingleMap.date)}}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div class="text-body-2">Название</div>
                <v-text-field disabled class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details v-model="getSingleMap.title" />
                <div class="text-body-2">Описание</div>
                <v-text-field disabled class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details v-model="getSingleMap.description" />
                <div class="text-body-2">Техпроцесс</div>
                <v-select disabled dense flat outlined class="mb-4" v-model="getSingleMap.techprocess" :items="GET_TECH_PROCESS" item-value="id" item-text="title" placeholder="Техпроцесс" solo hide-details />
                
                <div class="text-body-2">Группа</div>
                <v-select disabled dense flat outlined class="mb-4" v-model="getSingleMap.category" :items="GET_TECH_MAPS_CATS" item-value="id" item-text="name" placeholder="Группа" solo hide-details />
            </v-col>
            <v-col cols="6" >
                <v-card elevation="0" color="grey lighten-3" class="mt-4">
                    <v-tabs v-model="tab" background-color="grey lighten-3">
                        <v-tab>Продукция</v-tab>
                        <v-tab>Материалы</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <div class="grey lighten-3 pa-4 d-flex">
                                <v-row>
                                    <v-col cols="6" class="pr-1">
                                        
                                        <v-autocomplete :items="getGoods" item-value="id" item-text="title" dense flat outlined solo hide-details class="my-4 mr-2" v-for="(prod, i) in getSingleMap.goods" :key="i" v-model="prod.good" placeholder="Поиск по наименованию" disabled />
                                    </v-col>
                                    <v-col cols="6" class="pl-1">
                                        <v-text-field class="my-4 ml-2" dense flat outlined solo placeholder="Введите объем" hide-details type="number" v-model="getSingleMap.goods[0].weight" suffix="кг" disabled />
                                    </v-col>
                                </v-row>
                                <!-- <v-autocomplete :items="getGoods" item-value="id" item-text="title" dense flat outlined solo hide-details class="my-4 mr-2" return-object v-model="good.good.id" placeholder="Поиск по наименованию"></v-autocomplete>
                                <v-text-field class="my-4 ml-2" dense flat outlined solo placeholder="Введите объем" hide-details type="number" v-model="good.weight" 
                                :suffix="good.good.customfields.eizm" /> -->
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="grey lighten-3 pa-4 text-end">
                                <v-simple-table class="mb-3" style="border:1px solid #0000001f;">
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Наименование</th>
                                            <th>Норма по техкарте</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in getSingleMap.techprocess_data[0].part" :key="i">
                                            <td><v-autocomplete :items="getParts" item-value="id" item-text="title" dense flat outlined solo hide-details class="my-4" 
                                            v-model="item.name.id" placeholder="Поиск по наименованию" disabled></v-autocomplete></td>
                                            <td><v-text-field class="my-4" dense flat outlined solo placeholder="Введите объем" hide-details type="number" v-model="item.weight" :suffix="item.name.customfields.eizm" disabled /></td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import formatDate from '@/mixins/formatDate'

  export default {
    mixins: [formatDate],
    props: ["id"],
    data(){
      return {
        tab: 0
      }
    },
    computed: {
      ...mapGetters({
        getSingleMap: "techMaps/getSingleMap",
        GET_TECH_PROCESS: "techProcess/GET_TECH_PROCESS",
        GET_TECH_MAPS_CATS: 'techMaps/GET_TECH_MAPS_CATS',
        getGoods: "goods/GET_GOODS",
        getParts: "parts/getParts",
      }),
    },
    methods: {
      ...mapActions({
        UPDATE_GOODS: "goods/UPDATE_GOODS",
        loadTechMapCats: 'techMaps/loadTechMapCats',
        LOAD_SINGLE_MAP: "techMaps/LOAD_SINGLE_MAP",
        UPDATE_TECH_PROCESS: "techProcess/UPDATE_TECH_PROCESS",
        loadParts: "parts/loadParts"
      })
    },
    async created(){
      await this.loadParts()
      await this.UPDATE_GOODS()
      await this.UPDATE_TECH_PROCESS()
      await this.loadTechMapCats()
      await this.LOAD_SINGLE_MAP(this.id)
    }
  }
</script>