import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '@/views/Login.vue'

// техоперации
import TechOperations from '@/views/techOperations/TechOperations.vue'
import TechOperationCard from '@/views/techOperations/TechOperationCard.vue'
import NewOperation from '@/views/techOperations/NewOperation.vue'

// техкарты
import TechMaps from '@/views/techMaps/TechMaps.vue'
import NewMap from '@/views/techMaps/NewMap.vue'
import TechMapCard from '@/views/techMaps/TechMapCard.vue'

// приемки
import Priemki from '@/views/priemki/Priemki.vue'
import PriemkaCard from '@/views/priemki/PriemkaCard.vue'
import NewPriemka from '@/views/priemki/NewPriemka.vue'

// остатки
import Parts from '@/views/parts/Parts.vue'
import PartsCard from '@/views/parts/PartsCard.vue'
import NewPart from '@/views/parts/NewPart.vue'

// товары
import Goods from '@/views/goods/Goods.vue'
import GoodsCard from '@/views/goods/GoodsCard.vue'
import NewGood from '@/views/goods/NewGood.vue'

// контрагенты
import Contragents from '@/views/contragents/Contragents.vue'
import NewAgent from '@/views/contragents/NewAgent.vue'
import AgentCard from '../views/contragents/AgentCard.vue'

// posting
import Posting from '@/views/posting/Posting.vue'

// write-offs
import WriteOffs from '@/views/write_offs/WriteOffs.vue'

// Inventory
import Inventory from '@/views/inventory/Inventory.vue'

// bills
import Bills from '@/views/bills/Bills.vue'

// Refunds
import Refunds from '@/views/refunds/Refunds.vue'

// brands
import Brands from '@/views/brands/Brands.vue'

// groups
import Groups from '../views/groups/Groups.vue'

// maps-cats
import MapsCats from '@/views/maps_cats/MapsCats.vue'

import TechProcess from '@/views/TechProcess.vue'


import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  // tech-operations
  {
    path: '/',
    component: TechOperations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tech-operations/:id',
    component: TechOperationCard,
    props: true
  },
  {
    path: '/new-operation',
    component: NewOperation
  },
  // login
  {
    path: '/enter',
    component: Login
  },
  // priemki
  {
    path: '/priemki',
    component: Priemki
  },
  {
    path: '/priemki/:id',
    component: PriemkaCard,
    props: true
  },
  {
    path: '/new-priemka',
    component: NewPriemka
  },
  // parts
  {
    path: '/parts',
    component: Parts
  },
  {
    path: '/new-part',
    component: NewPart
  },
  {
    path: '/parts/:id',
    component: PartsCard,
    props: true
  },

  // tech-process
  {
    path: '/tech-process',
    component: TechProcess
  },
  {
    path: '/tech-process/card/:id',
    // component: TechProcessCard,
  },
  {
    path: '/tech-maps',
    component: TechMaps,
  },
  {
    path: '/new-map',
    component: NewMap
  },
  {
    path: '/tech-maps/:id',
    component: TechMapCard,
    props: true
  },
  // goods
  {
    path: '/goods',
    component: Goods
  },
  {
    path: '/goods/:id',
    component: GoodsCard,
    props: true
  },
  {
    path: '/new-good',
    component: NewGood
  },

  // оприходывания
  {
    path: '/posting',
    component: Posting
  },
  // списания
  {
    path: '/write-offs',
    component: WriteOffs
  },

  // инвентаризация
  {
    path: '/inventory',
    component: Inventory
  },

  // счета
  {
    path: '/bills',
    component: Bills
  },

  // возвраты
  {
    path: '/refunds',
    component: Refunds
  },

  //  бренды
  {
    path: '/brands',
    component: Brands
  },
  //  группы
  {
    path: '/groups',
    component: Groups
  },
  // категории техкатр
  {
    path: '/tech-maps-cats',
    component: MapsCats
  },
  // контрагенты
  {
    path: '/contragents',
    component: Contragents
  },
  {
    path: '/new-agent',
    component: NewAgent
  },
  {
    path: '/contragents/:id',
    component: AgentCard,
    props: true
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/getAuthenticated"]) {
      next();
      return;
    }
    next("/enter");
  } else {
    next();
  }
});

export default router
