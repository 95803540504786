import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth.js'
import techMaps from './techMaps'
import techProcess from './techProcess'
import techOperations from './techOperations.js'
import goods from './goods'
import parts from './parts'
import global from './global'
import brands from './brands'
import maps_cats from './maps_cats'
import contragents from './contragents.js'
import groups from './groups.js'

export default new Vuex.Store({
	modules: {
		auth,
		techMaps,
		techOperations,
		techProcess,
		goods,
		parts,
		global,
		brands,
		maps_cats,
		contragents,
		groups
	}
}) 
