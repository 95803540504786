<template>
  <v-app>
    <Header />
    <Navigation /> 
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Navigation from "./components/Navigation.vue";
import "./assets/css/style.css";
import {mapGetters, mapActions} from 'vuex'



export default {
  components: { Header, Navigation },
  methods: {
    ...mapActions({
        UPDATE_TECH_MAPS: "techMaps/UPDATE_TECH_MAPS",
      }),
  },
  data() {
    return {
      name: "UNIDAN WEB CRM",
    };
  },
  async created(){
    await this.UPDATE_TECH_MAPS("/wp-json/ud/v1/techcards");
  }
};
</script>
