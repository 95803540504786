<template>
    <v-container>
        <v-row v-if="singlePart">
            <!-- <pre>{{singlePart}}</pre> -->
            <v-col cols="12" lg="12">
                <v-btn to="/parts" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Редактировать материал № 
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>{{id}}</v-chip>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="singlePart">
            <v-col>
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="singlePart.title" />
            </v-col>
            <v-col>
                <div class="text-body-2">Описание</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите описание" hide-details
                    v-model="singlePart.description" />
            </v-col>
            <v-col>
                <div class="text-body-2">Остаток</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите остаток" hide-details
                    v-model="singlePart.customfields.ostatok" />
            </v-col>
            <v-col>
                <div class="text-body-2">Единица измерения</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="кг/л..." hide-details
                    v-model="singlePart.customfields.eizm" />
            </v-col>
        </v-row>
        <v-row v-if="singlePart">
            <v-col cols="12" lg="3" class="pt-0">
                <v-btn dark color="primary" rounded :loading="loading" @click="updatePart">обновить</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="singlePart">
            <v-col cols="12">
                <div class="text-h5 font-weight-medium mb-4 mt-4">История техопераций</div>
                 <v-data-table :headers="headers" :loading="loading" :items="singlePart.ostatok_history" class="elevation-1"  :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}">
                    <template v-slot:item.techoperation="{ item }">
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small v-if="item.techoperation.id">
                        {{item.techoperation.id}}
                        </v-chip>
                        <v-chip label dark text-color="red" color="#f4433640" class="font-weight-bold" small v-else>Удаление</v-chip>
                    </template>
                    
                    <template v-slot:item.date="{ item }">
                        <span>{{formatDate(item.date)}}</span>
                    </template>
                   
                    <template v-slot:item.change_value="{ item }">
                        <v-chip label dark 
                        :text-color="item.change_value[0] === '+' ? '#36ac50': 'red' " 
                        :color="item.change_value[0] === '+' ? '#36ac501a': '#f4433640' " 
                        class="font-weight-bold" small>
                            {{item.change_value}}
                        </v-chip>
                    </template> 

                    <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else class="py-6">
            <v-col cols="4" offset="4">
                <div class="text-center text-body-1">Загрузка данных</div>
                <v-progress-linear
                    indeterminate
                    color="primary"
                    ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import formatDate from '@/mixins/formatDate'

export default {
    props: ["id"],
    mixins: [formatDate],
    computed: {
        ...mapGetters({
            singlePart: 'parts/getSinglePart',
            loading: 'parts/getLoading'
        })
    },
    data(){
        return{
            headers: [
                {
                    text: "ID техоперации",
                    value: "techoperation"
                },
                {
                    text: "Название техоперации",
                    value: "techoperation.name"
                },
                {
                    text: "Тип",
                    value: "description"
                },
                {
                    text: "Измененное значение",
                    value: "change_value"
                },
                {
                    text: "Дата",
                    value: "date"
                },
            ]
        }
    },
    async created(){
        await this.loadSinglePart(this.id)
    },
    methods: {
        ...mapActions({
            loadSinglePart: 'parts/loadSinglePart',
            updateSinglePart: 'parts/updateSinglePart'
        }),
        async updatePart(){
            await this.updateSinglePart(this.singlePart).then(() => {
                this.$router.replace('/parts')
            })
        }
        // addPart(){
        //     let part ={
        //         title: this.title,
        //         description: this.description,
        //         customfields: {
        //             ostatok: this.ostatok,
        //             eizm: this.eizm
        //         }
        //     }
        //     this.ADD_NEW_PART(part).then(() => {
        //         this.$router.replace('/parts')
        //     })
        // }
    }
}
</script>
