<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="mb-8 mt-4 d-flex align-center justify-space-between">
          <div class="text-h5 font-weight-medium">
            Товары
          </div>
          <router-link tag="a" to="/new-good">
            <v-btn dark color="primary" class="ml-4" rounded>
              Добавить
            </v-btn>
          </router-link>
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-select class="mr-4" :items="brands" item-text="name" item-value="name" placeholder="Бредн" dense flat outlined solo hide-details v-model="selectedBrand" clearable />
          <v-text-field placeholder="Поиск" dense flat outlined solo append-icon="mdi-magnify" hide-details v-model="search" />
        </div>
        <v-data-table :headers="headers" :loading="GET_LOADING" :items="getItems" @dblclick:row="openGoodsCard" class="elevation-1" :search="search" :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}">
          <template v-slot:item.id="{ item }">
            <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>
              {{item.id}}
            </v-chip>
          </template>

          <template v-slot:item.ostatok="{ item }">
            <v-chip label dark text-color="#000" color="#0000002e" class="font-weight-bold" small>
              {{item.customfields.ostatok}} {{item.customfields.eizm}}
            </v-chip>
          </template>

          <template v-slot:item.date="{ item }">
            <span>{{formatDate(item.date)}}</span>
          </template>

          <template v-slot:item.customfields="{ item }">
            <span>{{item.customfields.marka.name}}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <!-- <v-btn class="mr-2" fab color="primary" x-small>
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn> -->
            <v-btn @click="DELETE_GOODS(item.id)" fab dark color="red" x-small text>
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations} from "vuex"
  import formatDate from '@/mixins/formatDate'

  export default {
    name: "Goods",
    mixins: [formatDate],
    props: {
      id: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        search: '',
        selectedBrand: null,
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Товары",
            value: "title"
          },
          {
            text: "Марка",
            value: "customfields"
          },
          {
            text: "Остаток",
            value: "ostatok"
          },
          {
            text: "Дата создания",
            value: "date"
          },
          {
            text: "Действия",
            value: "actions"
          },
        ],
        items: [],
      };
    },
    computed: {
      ...mapGetters({
        GET_GOODS: "goods/GET_GOODS",
        GET_LOADING: "goods/GET_LOADING",
        brands: 'brands/getBrands'
      }),
      getItems(){
        if(!this.selectedBrand){
          return this.GET_GOODS
        }
        let items = this.GET_GOODS.filter(item => item.customfields.marka.name === this.selectedBrand )
        return items
      }
    },

    async created() {
      await this.UPDATE_GOODS()
      await this.LOAD_BRANDS()
    },

    methods: {
      ...mapMutations({
        SET_CHOSEN_ITEM: "goods/SET_CHOSEN_ITEM",
      }),
      ...mapActions({
        UPDATE_GOODS: "goods/UPDATE_GOODS",
        DELETE_GOODS: "goods/DELETE_GOODS",
        LOAD_BRANDS: 'brands/LOAD_BRANDS',
      }),
      openGoodsCard(event, { item }){
          this.$router.push(`/goods/${item.id}`);
      }
    },
  };
</script>