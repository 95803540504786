<template>
    <v-container>
         <v-row v-if="form">
            <!-- <pre>{{singleAgent}}</pre> -->
            <v-col cols="12" lg="12">
                <v-btn to="/contragents" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Редактировать контрагента  
                        <v-chip label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>   
                            ID: {{id}}
                        </v-chip>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!--  -->
        <v-row v-if="form">
            <v-col cols="12" lg="4">
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="form.title" />

                <div class="text-body-2">E-mail</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите e-mail" hide-details
                    v-model="form.email" />
                <div class="text-body-2">Телефон</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="+7" hide-details
                    v-model="form.phone" v-mask="'+7 ### ###-##-##'" />
                    <div class="text-body-2">Группа</div>
                    <v-select
                    class="mb-4"
                    :items="groups"
                    chips multiple
                    item-text="name"
                    item-value="id"
                    placeholder="Группа"
                    v-model="form.gruppa"
                    dense flat outlined solo hide-details
                />

                <div class="text-body-2">Статус</div>
                <v-select
                    class="mb-4"
                    :items="statuses"
                    placeholder="Стаус"
                    v-model="form.status"
                    dense flat outlined solo hide-details
                />

                <div class="text-body-2">Комментарий</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите комментарий" hide-details
                    v-model="form.description" />
            </v-col>
        </v-row>
        <v-row v-if="form">
            <v-col cols="12" lg="3" class="pt-0">
                <v-btn dark color="primary" rounded :loading="loading" @click="updateAgent">обновить</v-btn>
            </v-col>
        </v-row>
        <!--  -->
        <v-row v-else class="py-6">
            <v-col cols="4" offset="4">
                <div class="text-center text-body-1">Загрузка данных</div>
                <v-progress-linear
                    indeterminate
                    color="primary"
                    ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ["id"],
    data(){
        return{
            form: null,
            statuses: [
                'Активный',
                'Новый',
                'Выслано предложение',
                'Переговоры',
                'Неактивный'
            ]
        }
    },
    async created(){
        this.form = await this.loadSingleAgent(this.id)
        await this.LOAD_GROUPS()
    },
    computed: {
        ...mapGetters({
            loading: 'contragents/getLoading',
            groups: 'groups/getGroups'
        })
    },
    methods: {
        ...mapActions({
            loadSingleAgent: 'contragents/loadSingleAgent',
            updateSingleAgent: 'contragents/updateSingleAgent',
            LOAD_GROUPS: 'groups/LOAD_GROUPS'
        }),
        updateAgent(){
            // this.form.gruppa.join()
            // console.log(this.form.gruppa.join())
            this.form.gruppa = this.form.gruppa.join()
            this.updateSingleAgent(this.form).then(() => {
                this.$router.replace('/contragents')
            })
        }
    } 
}
</script>
