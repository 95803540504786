import axios from 'axios'
import Vue from "vue";

const auth = {
    namespaced: true,
    state: {
        techMaps: [],
        loading: false,
        techMapsCats: [],
        singleMap: null
    },

    mutations: {
        SET_TECH_MAPS(state, payload) {
            state.techMaps = payload
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_TECH_MAPS_CATS(state, payload){
            state.techMapsCats = payload
        },
        SET_SINGLE_MAP(state, singleMap){
            state.singleMap = singleMap
        }
    },

    actions: {
        async LOAD_SINGLE_MAP({commit}, id){
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/techcards?id=${id}`)
                commit("SET_SINGLE_MAP", data)
            } catch (error) {
                console.log(error)
            }
        }, 
        async loadTechMapCats({commit}){
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/techcards/categories?type=techcart_category`)
                commit("SET_TECH_MAPS_CATS", data)
            } catch (error) {
                console.log(error)
            }
        },
        async UPDATE_TECH_MAPS({ commit }) {
            commit("SET_LOADING", true);
            try {
                const { data } = await axios.get(`https://unidan.webink.site/wp-json/ud/v1/techcards`)
                commit("SET_TECH_MAPS", data);
                return data
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        async CREATE_TECH_MAP({commit, dispatch}, techMap){
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.post(`https://unidan.webink.site/wp-json/ud/v1/techcards`, techMap)
                dispatch('UPDATE_TECH_MAPS')
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        },
        async DELETE_TECH_MAPS({ commit }, id) {
            commit("SET_LOADING", true)
            try {
                const { data } = await axios.delete(`https://unidan.webink.site/wp-json/ud/v1/techcards?id=${id}`)
                commit("SET_TECH_MAPS", data)
            } catch (error) {
                console.log(error)
            }
            finally {
                commit("SET_LOADING", false);
            }
        }
    },

    getters: {
        GET_TECH_MAPS_CATS(state){
            return state.techMapsCats
        },
        GET_TECH_MAPS(state) {
            return state.techMaps
        },
        GET_LOADING(state) {
            return state.loading;
        },
        getSingleMap(state){
            return state.singleMap
        }
    }
}

export default auth


