<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                <v-btn to="/goods" elevation="0" color="grey lighten-3" rounded exact-path small>
                        <v-icon x-small>mdi-arrow-left</v-icon>
                        Назад
                    </v-btn>
                <div class="mb-4 mt-4 d-flex align-center justify-space-between">
                    <div class="text-h5 font-weight-medium">Новый товар</div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3">
                <div class="text-body-2">Название</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="newGoodItem.title" />
                

                <div class="text-body-2">Описание</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="Введите название" hide-details
                    v-model="newGoodItem.description" />
                
                <div class="text-body-2">Единица измерения</div>
                <v-text-field class="mb-4" dense flat outlined solo placeholder="л/кг..." hide-details
                    v-model="newGoodItem.customfields.eizm" />
                
                <div class="text-body-2">Марка</div>
                 <v-autocomplete :items="brands" item-value="id" item-text="name" dense flat outlined solo hide-details v-model="newGoodItem.customfields.marka" placeholder="Поиск по бренду" />
                
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3" class="text-right ">
                <v-btn dark color="primary" :loading="loading" rounded class="ml-2" @click="addNewGood">Добавить</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    computed:{
        ...mapGetters({
            loading: "goods/GET_LOADING",
            brands: 'brands/getBrands'
        })
    },
    data(){
        return{
            newGoodItem: {
                title: '',
                description: '',
                customfields: {
                    eizm: '',
                    marka: null
                }
            }
        }
    },
    async created(){
         await this.LOAD_BRANDS()
    },
    methods: {
        ...mapActions({
            LOAD_BRANDS: 'brands/LOAD_BRANDS',
            CREATE_NEW_GOOD: 'goods/CREATE_NEW_GOOD'
        }),
        async addNewGood(){
            await this.CREATE_NEW_GOOD(this.newGoodItem).then(() => {
                this.$router.replace('/goods')
            })
        }
    }
}
</script>
