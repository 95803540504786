<template>
  <v-app-bar app color="primary" dark fixed clipped-left>
    <v-app-bar-nav-icon v-if="user" @click.stop="toggleDrawer" />
    <router-link tag="a" to="/" style="height:80%;">
      <img src="../assets/logo.svg" style="height:100%;" class="mx-5">
    </router-link>
    <!-- <div class="text-subtitle-1 font-weight-bold" v-if="user">
      {{ user.user_email }}
    </div> -->
    <div class="text-h6">
      UNIDAN CRM 
      <p style="font-size:12px;line-height:1.2rem;" class="mb-0">Система учета продукции</p>
    </div>
    <!-- <div style="font-size:12px;" class="white--text">
        Система учета продукции
       </div> -->
    <v-spacer />
   
    <v-btn text @click="signOut" v-if="user">
      Выход
    </v-btn>
  </v-app-bar>
</template>

<script>
  import {
    mapGetters
  } from "vuex";

  import {
    EventBus
  } from "../helpers/eventBus";

  export default {
    computed: {
      ...mapGetters({
        loginError: "auth/getLoginErr",
        user: "auth/getAuthenticated",
      }),
    },
    data() {
      return {
        sidebar: false,
      };
    },
    methods: {
      toggleDrawer() {
        EventBus.$emit("toggleDrawer");
      },

      signOut() {
        this.$store.dispatch("auth/SIGN_OUT").then(() => {
          this.$router.replace("/enter");
        });
      },
    },
  };
</script>