import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueMask from 'v-mask'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'  

Vue.config.productionTip = false

Vue.use(Vuetify)
Vue.use(VueMask)


import ru from 'vuetify/es5/locale/ru'

const user = JSON.parse(localStorage.getItem("user"))
store.dispatch("auth/VALIDATE", user);




new Vue({
  router,
  store,
  vuetify: new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru'
    }
  }),
  render: h => h(App)
}).$mount('#app')
