<template>
  <v-container fluid>
    <v-row class="py-6">
      <v-col cols="7" class="d-flex align-center">
        <div class="text-h5 font-weight-medium">
            Контрагенты
          </div>
          <router-link tag="a" to="/new-agent">
            <v-btn dark color="primary" class="ml-4" rounded>
              Добавить контрагента
            </v-btn>
          </router-link>
      </v-col>
      <v-col class="d-flex align-center">
        <v-select
          :items="statuses"
          placeholder="Стаус"
          v-model="activeStatus"
          clearable
          dense flat outlined solo hide-details
        ></v-select>
          <v-text-field class="ml-2" placeholder="Поиск" dense flat outlined solo append-icon="mdi-magnify" hide-details v-model="search" />
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="getItems" :loading="loading" class="elevation-2" :search="search"
        :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}" @dblclick:row="openAgentCard">
            <template v-slot:item.status="{ item }">
                <v-chip v-if="item.status === 'Активный'" label dark text-color="#36ac50" color="#36ac501a" class="font-weight-bold" small>
                    {{item.status}}
                </v-chip>
                <v-chip v-if="item.status === 'Новый'" label dark text-color="orange" color="orange lighten-5" class="font-weight-bold" small>
                    {{item.status}}
                </v-chip>
                <v-chip v-if="item.status === 'Выслано предложение'" label dark text-color="purple" color="purple lighten-5" class="font-weight-bold" small>
                    {{item.status}}
                </v-chip>
                <v-chip v-if="item.status === 'Переговоры'" label dark text-color="blue" color="primary lighten-5" class="font-weight-bold" small>
                    {{item.status}}
                </v-chip>
                <v-chip v-if="item.status === 'Неактивный'" label dark text-color="red" color="red lighten-5" class="font-weight-bold" small>
                    {{item.status}}
                </v-chip>
            </template>

            <template v-slot:item.gruppa="{ item }">
              
              <div v-if="groups.length">
                <v-chip class="mr-1" label small v-for="(gr, i) in item.gruppa" :key="i">
                    {{getGroupName(gr).name}}
              </v-chip>
              </div>
            </template>
          <template v-slot:item.actions="{ item }">
            <v-btn fab color="grey darken-3" x-small text @click="openAgentCard($event, {item})" >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn  fab dark color="red" x-small text @click="deleteAgent(item.id)">
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </template> 
          <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            suggest: '',
            search: '',
            items: [],
            activeStatus: null,
            statuses: [
                'Активный',
                'Новый',
                'Выслано предложение',
                'Переговоры',
                'Неактивный'
            ],
            headers: [
                {
                    text: "Название",
                    value: "title"
                },
                {
                    text: "E-mail",
                    value: "email"
                },
                {
                    text: "Телефон",
                    value: "phone"
                },
                {
                    text: "Группа",
                    value: "gruppa"
                },
                {
                    text: "Статус",
                    value: "status"
                },
                {
                    text: 'Комментарий',
                    value: 'description'
                },
                {
                    text: "Действия",
                    value: "actions"
                },
            ]
        }
    },
    computed: {
      ...mapGetters({
        loading: 'contragents/getLoading',
        groups: 'groups/getGroups'
      }),
      getItems(){
        if(this.activeStatus){
          return this.items.filter(i => i.status === this.activeStatus)
        }
        return this.items
      }
    },
    methods: {
        getGroupName(i){
          const groupName = this.groups.find(item => {
            return item.id === i
          })
          return groupName
        },
        deleteAgent(id){
            this.$store.dispatch('contragents/deleteAgent', id)
            .then((res) => {
                this.items = res
            })
        },
        ...mapActions({
            loadAgents: "contragents/loadAgents",
            LOAD_GROUPS: 'groups/LOAD_GROUPS'
        }),
        openAgentCard(event, { item }){
          this.$router.push(`/contragents/${item.id}`);
        }
    },
    async created() {
        this.items = await this.loadAgents()
        await this.LOAD_GROUPS()
    }
}
</script>