var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-6"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Приемки ")]),_c('router-link',{attrs:{"tag":"a","to":"/new-priemka"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","rounded":""}},[_vm._v(" Добавить ")])],1)],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"ml-2",attrs:{"placeholder":"Поиск","dense":"","flat":"","outlined":"","solo":"","append-icon":"mdi-magnify","hide-details":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"footer-props":{'items-per-page-options':[10],'disable-items-per-page': true,}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.contragent",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getAgent(item.contragent)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","color":"grey darken-3","x-small":"","text":"","to":("/priemki/" + (item.id))}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","color":"red","x-small":"","text":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"py-8"})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }