<template>
  <v-container fluid>
    <v-row class="py-6">
      <v-col cols="8" class="d-flex align-center">
        <div class="text-h5 font-weight-medium">
            Счета
          </div>
          <v-btn  color="grey lighten-1" class="ml-4" rounded>
              Добавить 
            </v-btn>
      </v-col>
      <v-col class="d-flex align-center">
          <v-text-field class="ml-2" placeholder="Поиск" dense flat outlined solo  append-icon="mdi-magnify" hide-details />
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :loading="loading" class="elevation-2"
        :footer-props="{'items-per-page-options':[10],'disable-items-per-page': true,}" >
            <template v-slot:item.date="{ item }">
                <span>{{formatDate(item.date)}}</span>
            </template>
            <template v-slot:item.description="{ item }">
                <span v-html="item.description"></span>
            </template>
          <template #footer.prepend> <v-spacer />  <div class="py-8"></div> </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from "vuex"
import formatDate from '../../mixins/formatDate'

export default {
    mixins: [formatDate],
    data() {
        return {
            items: [],
            headers: [
                {
                    text: "Название",
                    value: "title"
                },
                {
                    text: "Описание",
                    value: "description"
                },
                {
                    text: "Дата",
                    value: "date"
                },
                {
                    text: "Действия",
                    value: "actions"
                },
            ]
        }
    },
    computed: {
      ...mapGetters({
        loading: 'global/getLoading'
      }),
    },
    methods: {
        ...mapActions({
            LOAD_GLOBAL: 'global/LOAD_GLOBAL'
        })
    },
    async created(){
        this.items = await this.LOAD_GLOBAL('scheta')
    }
    // async created() {
    //     this.items = await this.loadParts()
    // }
}
</script>